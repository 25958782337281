@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  20% {
    transform: translateY(0) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw) scale(1.1);
    opacity: 0;
  }
}
.wrapper {
  background-color: #000;
  color: white;
  border: 2px solid #fff;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  bottom: 8px;
  right: 42%;
  border-radius: 24px;
  padding: 16px;
  z-index: 99999999999;
  animation: slideInUp 0.5s forwards;
  display: flex;
  flex-direction: column;

  &.error {
    border: 2px solid var(--error-text);
    background-color: #fff;

    .text {
      color: var(--error-text);
    }

    .linkButton {
      color: var(--error-text) !important;
      background-color: #fff;
      border: transparent;
    }

    .textWrapper {
      button {
        svg {
          color: var(--error-text);
        }
      }
    }
  }
}

.textWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}

.text {
  color: #fff;
}

.closed {
  animation: slideOutRight 2s forwards;
}

.linkButton {
  color: #fff !important;
  font-weight: bold;
  background-color: #000;
  border: transparent;
  font-family: var(--font-forma);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
