@font-face {
font-family: '__headerFont_1b584a';
src: url(/_next/static/media/888c511bd44fda1d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__headerFont_1b584a';
src: url(/_next/static/media/ac3c161d3bc7317d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__headerFont_Fallback_1b584a';src: local("Arial");ascent-override: 100.55%;descent-override: 33.52%;line-gap-override: 0.00%;size-adjust: 89.51%
}.__className_1b584a {font-family: '__headerFont_1b584a', '__headerFont_Fallback_1b584a'
}.__variable_1b584a {--font-forma: '__headerFont_1b584a', '__headerFont_Fallback_1b584a'
}

@font-face {
font-family: '__textFont_f8212b';
src: url(/_next/static/media/2104b1f9e8926dfa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__textFont_Fallback_f8212b';src: local("Arial");ascent-override: 104.55%;descent-override: 31.31%;line-gap-override: 0.00%;size-adjust: 95.17%
}.__className_f8212b {font-family: '__textFont_f8212b', '__textFont_Fallback_f8212b';font-weight: 400;font-style: normal
}.__variable_f8212b {--font-signifier: '__textFont_f8212b', '__textFont_Fallback_f8212b'
}

:root{--max-width: 1100px;--border-radius: 12px;--font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;--background-color: #efefef;--error-text: #cc4000;--success-text: #009900;--primary-white: #ffffff;--primary-00: #efefef;--primary-01: #e8e8e8;--primary-02: #d1d1d1;--primary-03: #bababa;--primary-04: #a3a3a3;--primary-05: #8c8c8c;--primary-06: #707070;--primary-07: #545454;--primary-08: #383838;--primary-09: #1c1c1c;--primary-10: #0f0f0f;--primary-11: #000000;--font-size-header: 56px;--hover-bg: var(--primary-01)}@media(prefers-color-scheme: dark){:root{--foreground-rgb: 255, 255, 255;--background-start-rgb: 0, 0, 0;--background-end-rgb: 0, 0, 0;--primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));--secondary-glow: linear-gradient( to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3) );--tile-start-rgb: 2, 13, 46;--tile-end-rgb: 2, 5, 19;--tile-border: conic-gradient( #ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80 );--callout-rgb: 20, 20, 20;--callout-border-rgb: 108, 108, 108;--card-rgb: 100, 100, 100;--card-border-rgb: 200, 200, 200}}*{box-sizing:border-box;padding:0;margin:0}html,body{max-width:100vw;overflow-x:hidden;background-color:#efefef}h1,h2,h3,h4,h5,h6,div{font-family:var(--font-forma);color:#000}button{color:#000}a{color:inherit;text-decoration:none}@media(prefers-color-scheme: dark){.next-error-h1{color:#fff !important}.next-error-h1+div h2{color:#fff !important}}
.CookieConsent_wrapper__iT77j{background-color:#fff;color:#000;border:3px solid #000;position:fixed;bottom:24px;width:300px;left:24px;border-radius:30px;z-index:999}.CookieConsent_content__iBiYm{padding:24px;display:flex;flex-direction:column;gap:8px}.CookieConsent_cookiesHeader__T8gRv{font-size:20px}.CookieConsent_info__n1Qig{display:flex;gap:8px;flex-direction:column;margin-left:8px}.CookieConsent_button__iiOII{padding:8px;background-color:#fff;color:#000;border-radius:7px;border:2px solid #000;margin-top:16px;font-weight:bold;font-size:18px;transition:.3s}.CookieConsent_button__iiOII:hover{cursor:pointer;background-color:#000;color:#fff;border:2px solid #fff}.CookieConsent_link__vZCzX{text-decoration:underline;font-weight:bold}@media screen and (max-width: 620px){.CookieConsent_wrapper__iT77j{width:90vw}}
@keyframes SnackBar_slideInUp__ECpaO{from{transform:translateY(100%);opacity:0}to{transform:translateY(0);opacity:1}}@keyframes SnackBar_slideOutRight__MganK{0%{transform:translateY(0) scale(1);opacity:1}20%{transform:translateY(0) scale(1.1);opacity:1}100%{transform:translateX(100vw) scale(1.1);opacity:0}}.SnackBar_wrapper__KAqqI{background-color:#000;color:#fff;border:2px solid #fff;width:-moz-fit-content;width:fit-content;position:fixed;bottom:8px;right:42%;border-radius:24px;padding:16px;z-index:99999999999;animation:SnackBar_slideInUp__ECpaO .5s forwards;display:flex;flex-direction:column}.SnackBar_wrapper__KAqqI.SnackBar_error__HZjg5{border:2px solid var(--error-text);background-color:#fff}.SnackBar_wrapper__KAqqI.SnackBar_error__HZjg5 .SnackBar_text__gWb_W{color:var(--error-text)}.SnackBar_wrapper__KAqqI.SnackBar_error__HZjg5 .SnackBar_linkButton__MBncj{color:var(--error-text) !important;background-color:#fff;border:rgba(0,0,0,0)}.SnackBar_wrapper__KAqqI.SnackBar_error__HZjg5 .SnackBar_textWrapper__sOxSf button svg{color:var(--error-text)}.SnackBar_textWrapper__sOxSf{display:flex;gap:4px;align-items:center}.SnackBar_text__gWb_W{color:#fff}.SnackBar_closed__8NpyS{animation:SnackBar_slideOutRight__MganK 2s forwards}.SnackBar_linkButton__MBncj{color:#fff !important;font-weight:bold;background-color:#000;border:rgba(0,0,0,0);font-family:var(--font-forma);text-decoration:none}.SnackBar_linkButton__MBncj:hover{text-decoration:underline}
