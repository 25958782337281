.wrapper {
  background-color: #fff;
  color: #000;
  border: 3px solid #000;
  position: fixed;
  bottom: 24px;
  width: 300px;
  left: 24px;
  border-radius: 30px;
  z-index: 999;
}

.content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cookiesHeader {
  font-size: 20px;
}

.info {
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-left: 8px;
}

.button {
  padding: 8px;
  background-color: #fff;
  color: #000;
  border-radius: 7px;
  border: 2px solid #000;
  margin-top: 16px;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border: 2px solid #fff;
  }
}

.link {
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (max-width: 620px) {
  .wrapper {
    width: 90vw;
  }
}
