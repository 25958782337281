:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --background-color: #efefef;

  --error-text: #cc4000;
  --success-text: #009900;

  /* primary */
  --primary-white: #ffffff;
  --primary-00: #efefef;
  --primary-01: #e8e8e8;
  --primary-02: #d1d1d1;
  --primary-03: #bababa;
  --primary-04: #a3a3a3;
  --primary-05: #8c8c8c;
  --primary-06: #707070;
  --primary-07: #545454;
  --primary-08: #383838;
  --primary-09: #1c1c1c;
  --primary-10: #0f0f0f;
  --primary-11: #000000;

  --font-size-header: 56px;

  --hover-bg: var(--primary-01);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #efefef;
}

h1,
h2,
h3,
h4,
h5,
h6,
div {
  font-family: var(--font-forma);
  color: #000000;
}

button {
  color: #000000;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  .next-error-h1 {
    color: white !important;
  }

  .next-error-h1 + div h2 {
    color: white !important;
  }
}
